
    import { defineComponent } from 'vue';
    import { chevronBackOutline, chevronForwardSharp } from 'ionicons/icons';
    import { store } from '../../store/store';
    import { LanguageMixin } from '@/mixins/LanguageMixin';
    import { SharedMixin } from '../../mixins/SharedMixin';
    import auth from 'authur';
    import {
        IonButton,
        IonImg,
        IonIcon,
        IonInput
    } from '@ionic/vue';
    import { MemberApiClient, ChangePasswordRequest } from '../../generated';

    export default defineComponent({
        name: 'Reset password',
        components: {
            IonButton,
            IonImg,
            IonIcon,
            IonInput
        },
        mixins: [SharedMixin, LanguageMixin],
        setup() {
            let loggedInUser = {};
            loggedInUser = store.state.loggedInUser;

            return {
                chevronBackOutline,
                chevronForwardSharp,
                loggedInUser
            }
        },
        data() {
            return {
                fboNumber: '',
                passwordCurrent: '',
                password: '',
                passwordAgain: '',
                passwordCurrent1: '',
                passwordCurrent2: '',
                passwordCurrent3: '',
                passwordCurrent4: '',
                passwordCurrent5: '',
                password1: '',
                password2: '',
                password3: '',
                password4: '',
                password5: '',
                password6: '',
                password7: '',
                password8: '',
                password9: '',
                password10: '',
                newPasswordScreen1: false,
                newPasswordScreen2: false,
                successScreen: false,
                errorMessage: false,
                errorMessagePassword: false,
                systemErrorMessage: false,
                userName: null
            }
        },
        ionViewDidEnter() {
            if (localStorage.getItem('userName')) {
                // @ts-ignore
                this.userName = localStorage.getItem('userName');
            }
        },
        watch: {
            passwordCurrent1: function (newVal, oldVal) {
                this.passwordCurrent = this.passwordCurrent1 + this.passwordCurrent2 + this.passwordCurrent3 + this.passwordCurrent4 + this.passwordCurrent5;
                console.log(this.passwordCurrent)
            },
            passwordCurrent2: function (newVal, oldVal) {
                this.passwordCurrent = this.passwordCurrent1 + this.passwordCurrent2 + this.passwordCurrent3 + this.passwordCurrent4 + this.passwordCurrent5;
                console.log(this.passwordCurrent)
            },
            passwordCurrent3: function (newVal, oldVal) {
                this.passwordCurrent = this.passwordCurrent1 + this.passwordCurrent2 + this.passwordCurrent3 + this.passwordCurrent4 + this.passwordCurrent5;
                console.log(this.passwordCurrent)
            },
            passwordCurrent4: function (newVal, oldVal) {
                this.passwordCurrent = this.passwordCurrent1 + this.passwordCurrent2 + this.passwordCurrent3 + this.passwordCurrent4 + this.passwordCurrent5;
                console.log(this.passwordCurrent)
            },
            passwordCurrent5: function (newVal, oldVal) {
                this.passwordCurrent = this.passwordCurrent1 + this.passwordCurrent2 + this.passwordCurrent3 + this.passwordCurrent4 + this.passwordCurrent5;
                console.log(this.passwordCurrent)

                if (this.passwordCurrent.length == 5 && this.password.length < 1) {
                    this.submitpassword()
                }
            },
            password1: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)
            },
            password2: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)
            },
            password3: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)
            },
            password4: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)
            },
            password5: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)

                if (this.password.length == 5 && this.passwordAgain.length < 1) {
                    this.newPasswordScreen2 = true;
                    this.newPasswordScreen1 = false;
                    this.focusOnFirstField('f11')
                }
            },
            password6: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)
            },
            password7: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)
            },
            password8: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)
            },
            password9: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)
            },
            password10: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)

                if (this.passwordAgain.length == 5) {
                    if (this.passwordAgain == this.password) {
                        const memberClient = new MemberApiClient();
                        const request = new ChangePasswordRequest();
                        request.password = this.passwordCurrent;
                        request.newPassword = this.password;
                        request.newPasswordConfirm = this.passwordAgain;

                        memberClient.changePassword(request).then(result => {
                            if (result != null && result.status == 200) {
                                this.errorMessage = false;
                                this.systemErrorMessage = false;
                                this.newPasswordScreen2 = false;
                                this.newPasswordScreen1 = false;
                                this.successScreen = true;
                            } else {
                                this.systemErrorMessage = true;
                                // andere melding voor als er iets op de server fout gaat.
                            }
                        }).catch(error => {
                            this.systemErrorMessage = true;
                        });

                    } else {
                        this.errorMessage = true;
                    }
                }
            }
        },
        methods: {
            goBack1: function () {
                this.newPasswordScreen1 = false;
            },
            goBack2: function () {
                this.newPasswordScreen1 = true
                this.newPasswordScreen2 = false;
            },
            submitpassword: async function () {
                const request = new ChangePasswordRequest();
                request.password = this.passwordCurrent;

                const client = new MemberApiClient();
                client.checkPassword(request).then(result => {
                    if (result) {
                        this.newPasswordScreen1 = true;
                        this.errorMessagePassword = false;
                    } else {
                        this.errorMessagePassword = true;
                    }
                });
            },
            focusOnFirstField: function (element: string) {
                const tid = setInterval(function () {
                    if (document.readyState !== 'complete') return;
                    clearInterval(tid);
                    const firstField: HTMLElement = document.getElementById(element) as HTMLElement;
                    firstField.focus();
                }, 200);
            },
            gotoNextField: function (e: Event) {
                const element: HTMLElement = e.srcElement as HTMLElement;

                // @ts-ignore
                if (e?.target?.value.length > 0) {
                    // @ts-ignore
                    element.parentElement.nextElementSibling?.childNodes[0].focus();
                }

            },
            deleteOrPreviousField: function (e: Event) {
                const element: HTMLElement = e.srcElement as HTMLElement;

                // @ts-ignore
                if (e?.target?.value.length < 1) {
                    // @ts-ignore
                    element.parentElement.previousElementSibling?.childNodes[0].focus();
                }
            }
        },
    });
